<template>
  <Modal
    :toggle.sync="toggle"
    :loading="loading"
    :styles="{ modalContent: { minHeight: '100px' } }"
  >
    <template v-slot:header>Reject Work Order Request</template>

    <valid-input
      v-model="reason"
      class="is-small"
      style="width:50px !important;"
      placeholder="Rejection Reason"
      aria-label="Rejection Reason"
      name="RejectionReason"
      label="Rejection Reason"
      vid="RejectionReason"
      type="textarea"
      spellcheck="true"
      rules="max:8000"
    />

    <template v-slot:footer>
      <div
        :style="{
          display: 'flex',
          justifyContent: 'space-between'
        }"
      >
        <b-button @click="toggle = false">Close</b-button>
        <b-button @click="sendReject" type="is-primary">Reject</b-button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import ValidInput from '@/components/inputs/ValidInput'

export default {
  props: {
    reject: Function
  },

  components: {
    Modal,
    ValidInput
  },

  data() {
    return {
      toggle: false,
      reason: '',
      loading: false
    }
  },

  methods: {
    sendReject() {
      this.loading = true
      this.reject({
        reason: this.reason
      })
    },
    open() {
      this.loading = false
      this.toggle = true
    },
    close() {
      this.toggle = false
    }
  }
}
</script>
