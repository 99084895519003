import { getWorkOrderTypes } from '@/services/WorkOrders/WorkOrderType/List'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import { GetMultiplePhotos } from '@/services/WorkOrderRequests/WorkOrderRequestPhoto/procedures/GetMultiplePhotos'
import { StoreMultiplePhotos } from '@/services/WorkOrderRequests/WorkOrderRequestPhoto/procedures/StoreMultiplePhotos'
import { deleteWorkOrderRequestPhoto } from '@/services/WorkOrderRequests/WorkOrderRequestPhoto/Delete'
import { getWorkOrderRequest } from '@/services/WorkOrderRequests/WorkOrderRequest/Get'
import { editWorkOrderRequest } from '@/services/WorkOrderRequests/WorkOrderRequest/Put'
import { getOwner } from '@/services/Roster/Owner/getOwner'
import { workOrderList } from '@/services/WorkOrders/store'
//
import $ from 'jquery'
import _get from 'lodash/get'
//

var DEFAULT_FORM_VALUES = {
  workOrderRequestID: 0,
  status: '',
  categoryID: 0,
  description: '',
  detailedDescription: '',
  summary: ''
}

export const methods = {
  async rejectRequest({ reason }) {
    console.log('rejectRequest', reason)

    // looks less glitchy with a small delay
    await new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, 1000)
    })

    await this.onFormSubmit({
      rejectionReason: reason,
      status: 'rejected'
    })

    this.$refs.rejectModal.close()
  },

  async getCategories() {
    if (this.isDebug === true) console.debug('in getCategories()...')
    if (this.categories.length >= 1) {
      return
    }

    const { list, message, successful } = await getWorkOrderTypes()
    if (!successful) {
      console.error(message)
      return
    }

    this.categories = list.map(m => ({
      value: m.workOrderTypeID,
      label: m.name
    }))

    if (this.isDebug === true)
      console.debug('in getCategories()...' + JSON.stringify(this.categories))
  },

  locationTypeChanged() {
    if (this.locationType === 'Common Area') {
      console.debug('blur locationTypeChanged' + this.locationType)
      this.hideShowMapFlag = true

      if (this.hideShowMapFlag == true) {
        this.hideShowMapText = 'Hide Map'
        this.locationTypeText = 'Location Type - Place marker on map'
      }
    } else {
      this.hideShowMapFlag = false

      if (this.hideShowMapFlag == false) {
        this.hideShowMapText = 'Show Map'
        this.locationTypeText = 'Location Type'
      }
    }
  },

  async reload() {
    this.loading = true

    this.id = this.$route.params.id

    await this.getAccessToken()

    if (this.isDebug == true) console.debug('workOrderRequestID=' + this.workOrderRequestID)

    this.formData = { ...DEFAULT_FORM_VALUES }

    await this.getCategories()

    this.populateLocationTypes()

    const { result, message, successful } = await getWorkOrderRequest({
      id: this.id
    })
    if (!successful) {
      this.requestId = this.id
      this.loading = false

      notifyProblem(message)
      return
    }

    if (this.isDebug === true) console.debug('workOrderRequest=' + JSON.stringify(result))

    this.workOrderRequest = result
    this.formData.status = _get(result, ['status'], '')
    this.formData.category = _get(result, ['workOrderTypeID'], 0)
    console.debug('workOrderTypeID=' + this.formData.category)
    this.formData.detailedDescription = _get(result, ['detailedDescription'], '')
    this.formData.description = _get(result, ['description'], '')
    this.formData.rejectionReason = _get(result, ['rejectionReason'], '')
    this.formData.locationType = _get(result, ['locationType'], '')
    this.formData.latitude = _get(result, ['latitude'], 0)
    this.formData.longitude = _get(result, ['longitude'], 0)

    console.log('lat=' + _get(result, ['latitude'], 0))

    await this.retrievePhotos({
      photos: _get(result, ['workOrderRequestPhotos'], [])
    })

    this.loading = false
  },

  async getAccessToken() {
    await workOrderList
      .dispatch('getAccessToken', {
        credentialsName: 'google-maps-api-key'
      })
      .then(({ result }) => {
        if (result) {
          this.accessToken = result.credentialValue
        }
      })
  },
  async populateLocationTypes() {
    await workOrderList.dispatch('loadLocationTypesManagement').then(({ list }) => {
      if (list) {
        this.locationTypeList = list
        this.locationType = '<-Select a Location Type->'
      }
    })
  },

  async reloadParentImages() {
    if (this.isDebug === true) console.debug('reloadParentImages...')
    await this.reload()
  },

  hideShowMap() {
    console.debug('in hideShowMap...')
    this.hideShowMapFlag = !this.hideShowMapFlag

    if (this.hideShowMapFlag == true) {
      this.hideShowMapText = 'Hide Map'
    } else {
      this.hideShowMapText = 'Show Map'
    }
  },

  async addPhotos() {
    this.photoModalLoading = true

    const id = _get(this, ['$route', 'params', 'id'], '')

    const { problems, message } = await StoreMultiplePhotos({
      photos: this.adderPhotos,
      params: {
        workOrderRequestID: id
      }
    })
    if (problems >= 1) {
      notifyProblem(message)
    }

    await this.reload()

    this.photoModalOpen = false
    this.photoModalLoading = false
  },

  async adderPhotosUpdated({ items }) {
    this.adderPhotos = items
  },

  async showPhotoModal() {
    this.photoModalLoading = false
    this.photoModalOpen = true
  },

  async erasePhoto({ details }) {
    this.loading = true

    const { message, successful } = await deleteWorkOrderRequestPhoto({
      id: details.id
    })
    if (!successful) {
      notifyProblem(message)
      return
    }

    for (let a = 0; a < this.photos.length; a++) {
      const id = _get(this, ['photos', a, 'id'], null)
      if (typeof id === 'number' && id === details.id) {
        this.photos.splice(a, 1)
      }
    }

    this.reload()

    this.loading = false
  },

  confirmErasePhoto(details) {
    this.$buefy.dialog.confirm({
      title: 'Removing Request Photo',
      message: `Are you sure you want to <b>remove</b> the work order request photo?`,
      confirmText: 'Remove Request Photo',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => await this.erasePhoto(details)
    })
  },

  confirmRejectRequest() {
    this.$refs.rejectModal.open()
  },

  async promoteToWorkOrder() {
    if (this.isDebug == true) console.debug('promoteToWorkOrder...')
    this.hidePromoteMap = true
    this.toggle = true
  },

  async retrievePhotos({ photos }) {
    if (!Array.isArray(photos)) {
      return
    }

    const { problems, photos: photosContent, message } = await GetMultiplePhotos({
      paramsList: photos.map(photo => {
        const workOrderRequestPhotoID = _get(photo, ['workOrderRequestPhotoID'], '')

        return {
          workOrderRequestPhotoID,
          asBase64: true
        }
      })
    })
    if (problems !== 0) {
      notifyProblem(message)
      return
    }

    this.photos = photosContent
  },

  returnToParent() {
    this.$router.push({
      name: 'workOrderRequests'
    })
  },

  async cancelRequest() {
    if (!this.formData.rejectionReason || this.formData.rejectionReason == undefined) {
      notifyError('The rejection reason is required when cancelling a work order request.')
      return
    }

    this.formData.status = 'cancelled'

    await this.onFormSubmit()
  },

  async onFormSubmit({ rejectionReason = '', status: _status = '' } = {}) {
    if (
      !this.formData.category ||
      this.formData.category == undefined ||
      !this.formData.detailedDescription ||
      this.formData.detailedDescription == undefined
    ) {
      notifyError('Please select a catgory and enter a description. Both are required fields.')
      return
    }

    if (!this.workOrderRequest || this.workOrderRequest == undefined) {
      notifyError('There was a problem processing your updates.')
      return
    }

    this.loading = true

    const detailedDescription = this.formData.detailedDescription
    const category = this.formData.category
    const locationType = this.formData.locationType
    const description = this.formData.description

    const latitudeTemp = $('#formLatitude')
    const longitudeTemp = $('#formLongitude')
    const latitude =
      latitudeTemp && latitudeTemp !== undefined && latitudeTemp.text() !== ''
        ? $('#formLatitude').text()
        : this.workOrderRequest.latitude
    const longitude =
      longitudeTemp && longitudeTemp !== undefined && longitudeTemp.text() !== ''
        ? $('#formLongitude').text()
        : this.workOrderRequest.longitude

    if (this.isDebug === true) console.debug(this.formData.latitude + ' formLatitude=' + latitude)
    if (this.isDebug === true)
      console.debug(this.formData.longitude + ' formLongitude=' + longitude)

    const submitterName = _get(this, ['workOrderRequest', 'submitterName'], null)
    const submitterType = _get(this, ['workOrderRequest', 'submitterType'], null)

    let status = this.formData.status
    if (this.formData.status !== 'cancelled') {
      status = _get(this, ['workOrderRequest', 'status'], null)
    }
    if (_status === 'rejected') {
      status = 'rejected'
    }

    const workOrderRequestID = _get(this, ['workOrderRequest', 'workOrderRequestID'], null)

    const { message, successful } = await editWorkOrderRequest({
      json: {
        workOrderRequestID,
        hoaID: this.workOrderRequest.hoaID,
        workOrderTypeID: category,
        sourceUnitID: this.workOrderRequest.sourceUnitID,
        detailedDescription: detailedDescription,
        description: description,
        status,
        rejectionReason,
        submitterName,
        submitterType,
        submitterAspNetUserID: 'temp',
        locationType,
        latitude,
        longitude
      }
    })

    this.category = category
    this.detailedDescription = detailedDescription

    this.editMode = false
    this.loading = false

    if (!successful) {
      notifyError('There was a problem updating this work order request.')
      console.debug(message)
      return
    } else {
      this.reload()
      notifyMessage('This work order request has been updated.')
    }
  },

  async getParams() {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)
    const ownerID = _get(this, ['$store', 'state', 'user', 'authUser', 'ownerID'], null)

    let sourceUnitID = this.unitId

    if (ownerID) {
      const { owner, exception } = await getOwner({
        ownerID
      })
      if (exception) {
        console.debug(exception)
        return
      }

      sourceUnitID = _get(owner, ['unitID'], null)
    }

    return {
      hoaID,
      sourceUnitID
    }
  }
}
