import moment from 'moment'

var field = {
  width: '100px',
  display: 'inline-block',
  paddingRight: '6px',
  textAlign: 'left'
}

export const data = () => ({
  accessToken: '',
  formComplete: false,
  formData: {},
  loading: true,
  isDebug: true,
  categories: [],
  hidePromoteMap: false,
  id: 0,
  toggle: false,
  category: 0,
  photoModalLoading: false,
  photoModalOpen: false,
  photos: [],
  adderPhotos: [],
  retrieved: 0,
  workOrderRequest: null,
  locationTypeList: [],
  hideShowMapFlag: false,
  hideShowMapText: 'Show Map',
  locationTypeText: 'Location Type',
  formatDateFn: function(value) {
    return value && value != undefined
      ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
          .format('MM-DD-YYYY')
          .toString()
      : ''
  },
  styles: {
    filterButton: {
      float: 'right',
      marginTop: '4px'
    },
    amountField: Object.assign({}, field, {
      width: '125px'
    })
  }
})
