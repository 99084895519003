/*
  example:
    import { deleteWorkOrderRequestPhoto } from '@/services/WorkOrderRequests/WorkOrderRequestPhoto/Delete';

    const { result, message, successful } = await deleteWorkOrderRequestPhoto ({
      id: 
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import kms from '@/services/kms'
//
export async function deleteWorkOrderRequestPhoto({ kms: kms_ = kms, id } = {}) {
  const message = 'The work order request photo could not be deleted.'

  console.log('deleteWorkOrderRequestPhoto', { id })

  try {
    const result = await kms_.delete(`/WorkOrderRequests/WorkOrderRequestPhoto/${id}`)

    return {
      successful: true,
      result,
      message: ''
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    result: null,
    successful: false,
    message
  }
}

///////
