/*
  example:
    import { getWorkOrderRequest } from '@/services/WorkOrderRequests/WorkOrderRequest/Get';

    const { result, message, successful } = await getWorkOrderRequest ({
      id: 
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import kms from '@/services/kms'
//
export async function getWorkOrderRequest({ kms: kms_ = kms, id } = {}) {
  const message = 'This work order request could not be retrieved.'

  try {
    const result = await kms_.get(`/WorkOrderRequests/WorkOrderRequest/${id}`)

    return {
      successful: true,
      result,
      message: ''
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    result: null,
    successful: false,
    message
  }
}

///////
