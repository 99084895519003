<template>
  <PageContentLayoutOnly headerColor="#fafafa">
    <div workorderpromote class="notification headerColor" v-if="formData">
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div>
                  <valid-input
                    readonly="true"
                    style="width:50px !important;"
                    name="Status"
                    :label="
                      formData.status && formData.status === 'promoted'
                        ? `Status - Completed: ${formatDateFn(workOrderRequest.completedDate)}`
                        : 'Status'
                    "
                    type="text"
                    vid="Status"
                    placeholder="Status"
                    spellcheck="true"
                    aria-label="Status"
                    rules="max:20|required"
                    class="is-small"
                    v-model="formData.status"
                    :disabled="true"
                  />
                </div>
                <div class="is-12 pt-5" v-if="formData.status === 'rejected'">
                  <valid-input
                    type="textarea"
                    v-model="formData.rejectionReason"
                    :disabled="true"
                    class="is-small"
                    style="width:50px !important;"
                    name="RejectionReason"
                    label="Rejection Reason"
                    vid="RejectionReason"
                    spellcheck="true"
                    aria-label="Rejection Reason"
                    rules="max:8000"
                  />
                </div>
                <div class="pt-5">
                  <b-field :label="locationTypeText">
                    <b-select
                      @click.native="locationTypeChanged()"
                      :disabled="
                        formData.status &&
                        (formData.status === 'cancelled' ||
                          formData.status === 'rejected' ||
                          formData.status === 'promoted')
                          ? true
                          : false
                      "
                      v-model="formData.locationType"
                      required="true"
                      placeholder="Select a Location Type"
                    >
                      <option
                        v-for="option in locationTypeList"
                        :value="option.value"
                        :key="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div class="pt-5" v-if="categories">
                  <valid-select
                    label="Category"
                    placeholder="Category"
                    vid="category"
                    required="true"
                    rules="required"
                    v-model="formData.category"
                    :disabled="
                      formData.status &&
                      (formData.status === 'cancelled' ||
                        formData.status === 'rejected' ||
                        formData.status === 'promoted')
                        ? true
                        : false
                    "
                  >
                    <option
                      v-for="option in categories"
                      :value="option.value"
                      :key="option.value"
                      >{{ option.label }}</option
                    >
                  </valid-select>
                </div>
                <div class="is-12 pt-5">
                  <valid-input
                    style="width:50px !important;"
                    name="Summary"
                    label="Title"
                    type="text"
                    vid="Summary"
                    placeholder="Title"
                    spellcheck="true"
                    aria-label="Title"
                    rules="max:100|required"
                    class="is-small"
                    :disabled="
                      formData.status &&
                      (formData.status === 'cancelled' ||
                        formData.status === 'rejected' ||
                        formData.status === 'promoted')
                        ? true
                        : false
                    "
                    v-model="formData.description"
                  />
                </div>
                <div class="is-12 pt-5">
                  <valid-input
                    style="width:50px !important;"
                    name="Description"
                    label="Description"
                    type="textarea"
                    vid="Description"
                    placeholder="Description"
                    spellcheck="true"
                    aria-label="Description"
                    rules="max:8000|required"
                    class="is-small"
                    :disabled="
                      formData.status &&
                      (formData.status === 'cancelled' ||
                        formData.status === 'rejected' ||
                        formData.status === 'promoted')
                        ? true
                        : false
                    "
                    v-model="formData.detailedDescription"
                  />
                </div>

                <div v-show="true" class="columns is-12 pt-5">
                  <div class="column is-6">
                    <input type="hidden" v-model="formData.latitude" />
                  </div>
                  <div class="column is-6">
                    <input type="hidden" v-model="formData.longitude" />
                  </div>
                </div>
                <div
                  @click="hideShowMap"
                  style="cursor: pointer vertical-align: middle"
                  class="href-overflow-wrap"
                >
                  {{ hideShowMapText }}
                  <i
                    style="font-size: 8pt; vertical-align: baseline !important;"
                    class="icon-map"
                  />
                </div>
                <div class="font-bold">
                  Please drag and drop the pin to represent the location of the work order request.
                </div>
                <div
                  class="pt-6 pb-6"
                  v-if="hideShowMapFlag && (hideShowMapFlag === true || hideShowMapFlag === 'true')"
                >
                  <GoogleMap
                    mapStyle="satellite"
                    :accessToken="accessToken"
                    :longitude="
                      Number.parseFloat(formData && formData.longitude ? formData.longitude : 0)
                    "
                    :latitude="
                      Number.parseFloat(formData && formData.latitude ? formData.latitude : 0)
                    "
                    :defaultLongitude="
                      Number.parseFloat(formData && formData.longitude ? formData.longitude : 0)
                    "
                    :defaultLatitude="
                      Number.parseFloat(formData && formData.latitude ? formData.latitude : 0)
                    "
                    :zoomLevel="parseInt(19)"
                    :workOrderRequestId="parseInt(id)"
                    :reloadParent="reloadParentImages"
                  />
                </div>
                <div
                  class="card pt-6"
                  :style="{
                    padding: '20px',
                    position: 'relative',
                    marginTop: '30px'
                  }"
                >
                  <b-field label="Photos"></b-field>

                  <div :style="{ marginBottom: '12px' }">
                    <b-button
                      :disabled="
                        formData.status &&
                        (formData.status === 'cancelled' || formData.status === 'promoted')
                          ? true
                          : false
                      "
                      @click="showPhotoModal"
                      >Add Photos</b-button
                    >
                  </div>

                  <div :style="{ display: 'flex', flexWrap: 'wrap' }">
                    <div
                      v-for="(entry, index) in photos"
                      :key="index"
                      :style="{ height: '300px', width: '300px', margin: '4px' }"
                    >
                      <PictureFrame
                        :src="entry.photo"
                        :details="entry"
                        :erase="confirmErasePhoto"
                        :editMode="formData.status && formData.status === 'pending' ? true : false"
                      />
                    </div>
                  </div>
                </div>

                <div
                  :style="{
                    display: 'flex',
                    padding: '20px 0 0',
                    justifyContent: 'space-between'
                  }"
                >
                  <div>
                    <button
                      type="submit"
                      :disabled="
                        formData.status &&
                        (formData.status === 'cancelled' ||
                          formData.status === 'promoted' ||
                          formData.status === 'rejected')
                          ? true
                          : false
                      "
                      class="button is-primary is-rounded"
                      tests-id="update-work-order-button"
                    >
                      Save Changes
                    </button>
                  </div>

                  <div>
                    <button
                      @click.prevent="confirmRejectRequest()"
                      type="button"
                      :disabled="
                        formData.status &&
                        (formData.status === 'cancelled' ||
                          formData.status === 'promoted' ||
                          formData.status === 'rejected')
                          ? true
                          : false
                      "
                      class="button is-primary is-rounded"
                    >
                      Reject Request
                    </button>

                    <span class="pl-3"></span>

                    <button
                      @click.prevent="promoteToWorkOrder()"
                      type="button"
                      :disabled="
                        formData.status &&
                        (formData.status === 'cancelled' ||
                          formData.status === 'promoted' ||
                          formData.status === 'rejected')
                          ? true
                          : false
                      "
                      class="button is-primary is-rounded"
                    >
                      Promote to Work Order
                    </button>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>

    <RejectModal ref="rejectModal" :reject="rejectRequest" />

    <Modal :toggle.sync="photoModalOpen" :styles="{ modalContent: { height: '900px' } }">
      <template v-slot:header>Photo Adder</template>
      <template v-slot:footer>
        <b-button @click="addPhotos" type="is-primary" :style="{ float: 'right' }">Add</b-button>
      </template>
      <PhotoUploader ref="photoUploader" label="Photos" :update="adderPhotosUpdated" />
      <b-loading :is-full-page="false" v-model="photoModalLoading"></b-loading>
    </Modal>
    <Modal :toggle.sync="toggle">
      <WorkOrderModal :workOrderRequest="workOrderRequest" hidePromoteModal="true" />
    </Modal>

    <b-loading :is-full-page="false" v-model="loading" :can-cancel="false"></b-loading>
  </PageContentLayoutOnly>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
//
//
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
//
import GoogleMap from '@/components/googlemaps'
import PictureFrame from '@/components/Picture/Frame'
import PhotoUploader from '@/components/Fields/PhotoUploader'
import WorkOrderModal from '@/components/WorkOrderModal'
import Modal from '@/components/Modal'
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'
//
//
import RejectModal from './components/RejectModal'
//
import { data } from './keys/data'
import { methods } from './keys/methods'

// components

export default {
  name: 'UpdateWorkOrder',

  components: {
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider,
    PageContentLayoutOnly,
    PictureFrame,
    Modal,
    PhotoUploader,
    WorkOrderModal,
    GoogleMap,
    RejectModal
  },

  data,

  computed: {
    ...mapState({
      unitId: state => state.unit.selectedUnitId,
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    })
  },

  mounted() {
    this.reload()
  },

  watch: {
    hoaId() {
      this.reload()
    },
    completeToggle() {
      if (this.completeToggle == false) {
        if (this.isDebug == true) console.debug('...in completeToggle()')
        this.completeToggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
        this.reload()
      }
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  methods,

  i18n: {
    messages: {
      en: {
        workOrderRequestPromote: { title: 'Work Order Request Promote' }
      }
    }
  }
}
</script>

<style lang="scss">
#update-work-order .my-grid-class {
  height: 400px;
}

#update-work-order tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
